// components/serbatoi/MovimentiList.js
import React from 'react';

function MovimentiList({ movimenti, serbatoi, fornitori }) {
  const getSerbatoioNome = (id) => serbatoi.find(s => s.id === id)?.nome || 'Sconosciuto';
  const getFornitoreNome = (id) => fornitori.find(f => f.id === id)?.name || 'Sconosciuto';

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lotto</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serbatoio</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantità (L)</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fornitore</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Note</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {movimenti.map((movimento, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{movimento.data}</td>
              <td className="px-6 py-4 whitespace-nowrap">{movimento.lotto}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {movimento.tipo === 'Trasferimento'
                  ? `${getSerbatoioNome(movimento.serbatoioOriginId)} → ${getSerbatoioNome(movimento.serbatoioDestinationId)}`
                  : getSerbatoioNome(movimento.serbatoioId)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{movimento.tipo}</td>
              <td className="px-6 py-4 whitespace-nowrap">{movimento.quantita}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {(movimento.tipo === 'Ingresso' || movimento.tipo === 'Produzione') 
                  ? getFornitoreNome(movimento.fornitoreId) 
                  : '-'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {movimento.tipo === 'Produzione' ? movimento.noteProduzione : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MovimentiList;