import { useState, useEffect } from 'react';

function usePrezzi() {
  const [prezzi, setPrezzi] = useState(() => {
    const savedPrezzi = localStorage.getItem('quoteLattePrezzi');
    return savedPrezzi ? JSON.parse(savedPrezzi) : [];
  });

  useEffect(() => {
    localStorage.setItem('quoteLattePrezzi', JSON.stringify(prezzi));
  }, [prezzi]);

  const addPrezzo = (newPrezzo) => {
    setPrezzi(prev => [...prev, { ...newPrezzo, id: Date.now().toString() }]);
  };

  const deletePrezzo = (id) => {
    setPrezzi(prev => prev.filter(prezzo => prezzo.id !== id));
  };

  return { prezzi, addPrezzo, deletePrezzo };
}

export default usePrezzi;