import React, { useState, useCallback, useEffect } from 'react';
import ResaForm from '../components/resa/ResaForm';
import ResaList from '../components/resa/ResaList';
import ResaChart from '../components/resa/ResaChart';
import ProductManager from '../components/resa/ProductManager';
import IngredientManager from '../components/resa/IngredientManager';
import useResa from '../hooks/useResa';
import useSerbatoi from '../hooks/useSerbatoi';

function Resa() {
  const { 
    rese, 
    addResa, 
    products, 
    addProduct, 
    removeProduct, 
    commonIngredients, 
    addIngredient, 
    removeIngredient, 
    calculateAverageResa 
  } = useResa();
  const { getLatteInProduzione, subtractLatteUsato } = useSerbatoi();
  const [activeTab, setActiveTab] = useState('add');
  const [latteInProduzione, setLatteInProduzione] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    if (selectedDate) {
      const totalLatte = getLatteInProduzione(selectedDate);
      setLatteInProduzione(totalLatte);
    }
  }, [selectedDate, getLatteInProduzione]);

  const handleAddResa = useCallback((newResa) => {
    addResa(newResa);
    subtractLatteUsato(newResa.data, parseFloat(newResa.latteUsato));
    setLatteInProduzione(prev => Math.max(0, prev - parseFloat(newResa.latteUsato)));
  }, [addResa, subtractLatteUsato]);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Gestione Resa del Latte</h1>

      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {['add', 'list', 'chart', 'manage'].map((tab) => (
              <button
                key={tab}
                className={`${
                  activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                onClick={() => setActiveTab(tab)}
              >
                {tab === 'add' && 'Aggiungi Resa'}
                {tab === 'list' && 'Lista Rese'}
                {tab === 'chart' && 'Grafico Rese'}
                {tab === 'manage' && 'Gestione Prodotti/Ingredienti'}
              </button>
            ))}
          </nav>
        </div>
      </div>
      
      {activeTab === 'add' && (
        <ResaForm 
          onSubmit={handleAddResa} 
          products={products} 
          commonIngredients={commonIngredients}
          latteInProduzione={latteInProduzione}
          onDateChange={handleDateChange}
        />
      )}
      {activeTab === 'list' && <ResaList rese={rese} />}
      {activeTab === 'chart' && <ResaChart rese={rese} products={products} />}
      {activeTab === 'manage' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ProductManager 
            products={products} 
            addProduct={addProduct} 
            removeProduct={removeProduct} 
          />
          <IngredientManager 
            ingredients={commonIngredients} 
            addIngredient={addIngredient} 
            removeIngredient={removeIngredient} 
          />
        </div>
      )}

      <div className="mt-6 p-4 bg-gray-100 rounded-md">
        <h2 className="text-lg font-semibold mb-2">Resa Media</h2>
        <p>La resa media è: {calculateAverageResa()}%</p>
      </div>
    </div>
  );
}

export default Resa;