// hooks/useResa.js
import { useState, useEffect } from 'react';

function useResa() {
  const [rese, setRese] = useState(() => {
    const savedRese = localStorage.getItem('quoteLatteRese');
    return savedRese ? JSON.parse(savedRese) : [];
  });

  const [products, setProducts] = useState(() => {
    const savedProducts = localStorage.getItem('quoteLatteProducts');
    return savedProducts ? JSON.parse(savedProducts) : ['Mozzarella', 'Scamorza', 'Ricotta'];
  });

  const [commonIngredients, setCommonIngredients] = useState(() => {
    const savedIngredients = localStorage.getItem('quoteLatteIngredients');
    return savedIngredients ? JSON.parse(savedIngredients) : ['Sale', 'Caglio', 'Acido citrico'];
  });

  useEffect(() => {
    localStorage.setItem('quoteLatteRese', JSON.stringify(rese));
  }, [rese]);

  useEffect(() => {
    localStorage.setItem('quoteLatteProducts', JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    localStorage.setItem('quoteLatteIngredients', JSON.stringify(commonIngredients));
  }, [commonIngredients]);

  const addResa = (newResa) => {
    const totalMilk = parseFloat(newResa.latteUsato);
    const resaWithCalculations = {
      ...newResa,
      prodotti: newResa.prodotti.map(prodotto => ({
        ...prodotto,
        resaPercentuale: ((parseFloat(prodotto.quantita) / totalMilk) * 100).toFixed(2)
      })),
      ingredienti: newResa.ingredienti.map(ingrediente => ({
        ...ingrediente,
        quantita: parseFloat(ingrediente.quantita)
      }))
    };
    setRese(prev => [...prev, { ...resaWithCalculations, id: Date.now().toString() }]);
    return totalMilk;
  };

  const addProduct = (product) => {
    setProducts(prev => [...prev, product]);
  };

  const removeProduct = (product) => {
    setProducts(prev => prev.filter(p => p !== product));
  };

  const addIngredient = (ingredient) => {
    setCommonIngredients(prev => [...prev, ingredient]);
  };

  const removeIngredient = (ingredient) => {
    setCommonIngredients(prev => prev.filter(i => i !== ingredient));
  };

  const calculateAverageResa = () => {
    if (rese.length === 0) return 0;
    const totalResa = rese.reduce((sum, resa) => {
      const resaPercentuale = resa.prodotti.reduce((prodSum, prod) => prodSum + parseFloat(prod.resaPercentuale), 0);
      return sum + (resaPercentuale / resa.prodotti.length);
    }, 0);
    return (totalResa / rese.length).toFixed(2);
  };

  const calculateResaByProduct = (product) => {
    const productRese = rese.filter(r => r.prodotti.some(p => p.nome === product));
    if (productRese.length === 0) return 0;
    const totalResa = productRese.reduce((sum, resa) => {
      const productResa = resa.prodotti.find(p => p.nome === product).resaPercentuale;
      return sum + parseFloat(productResa);
    }, 0);
    return (totalResa / productRese.length).toFixed(2);
  };

  return { 
    rese, 
    addResa, 
    products, 
    addProduct, 
    removeProduct, 
    commonIngredients, 
    addIngredient, 
    removeIngredient, 
    calculateAverageResa,
    calculateResaByProduct
  };
}

export default useResa;