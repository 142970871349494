import React, { useState } from 'react';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import useQuoteLatte from '../hooks/useQuoteLatte';
import useSerbatoi from '../hooks/useSerbatoi';
import useFornitori from '../hooks/useFornitori';
import usePrezzi from '../hooks/usePrezzi';
import useResa from '../hooks/useResa';

function Documentazione() {
  const { campioni } = useQuoteLatte();
  const { serbatoi, movimenti } = useSerbatoi();
  const { fornitori } = useFornitori();
  const { prezzi } = usePrezzi();
  const { rese } = useResa();

  const [reportType, setReportType] = useState('');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const generateReport = () => {
    const doc = new jsPDF();
    
    switch(reportType) {
      case 'qualitaLatte':
        generateQualitaLatteReport(doc);
        break;
      case 'movimentiLatte':
        generateMovimentiLatteReport(doc);
        break;
      case 'prezziLatte':
        generatePrezziLatteReport(doc);
        break;
      case 'resa':
        generateResaReport(doc);
        break;
      default:
        alert('Seleziona un tipo di report');
        return;
    }

    doc.save(`report_${reportType}_${new Date().toISOString().split('T')[0]}.pdf`);
  };

  const generateQualitaLatteReport = (doc) => {
    doc.text('Report Qualità Latte', 14, 15);
    const tableData = campioni
      .filter(c => new Date(c.date) >= new Date(dateRange.start) && new Date(c.date) <= new Date(dateRange.end))
      .map(c => [c.date, c.cbt, c.celluleSomatiche, c.proteine, c.grassi]);
    
    doc.autoTable({
      head: [['Data', 'CBT', 'Cellule Somatiche', 'Proteine', 'Grassi']],
      body: tableData,
    });
  };

  const generateMovimentiLatteReport = (doc) => {
    doc.text('Report Movimenti Latte', 14, 15);
    const tableData = movimenti
      .filter(m => new Date(m.data) >= new Date(dateRange.start) && new Date(m.data) <= new Date(dateRange.end))
      .map(m => [m.data, serbatoi.find(s => s.id === m.serbatoioId)?.nome, m.tipo, m.quantita]);
    
    doc.autoTable({
      head: [['Data', 'Serbatoio', 'Tipo', 'Quantità']],
      body: tableData,
    });
  };

  const generatePrezziLatteReport = (doc) => {
    doc.text('Report Prezzi Latte', 14, 15);
    const tableData = prezzi
      .filter(p => new Date(p.dataInizio) >= new Date(dateRange.start) && new Date(p.dataInizio) <= new Date(dateRange.end))
      .map(p => [p.dataInizio, p.prezzoBase, p.bonusProteine, p.bonusGrassi, p.penalidadCellule, p.penalidadCaricaBatterica]);
    
    doc.autoTable({
      head: [['Data Inizio', 'Prezzo Base', 'Bonus Proteine', 'Bonus Grassi', 'Penalità Cellule', 'Penalità Carica Batterica']],
      body: tableData,
    });
  };

  const generateResaReport = (doc) => {
    doc.text('Report Resa', 14, 15);
    const tableData = rese
      .filter(r => new Date(r.data) >= new Date(dateRange.start) && new Date(r.data) <= new Date(dateRange.end))
      .map(r => [
        r.data,
        r.latteUsato,
        r.prodotti.map(p => `${p.nome}: ${p.quantita}Kg (${p.resaPercentuale}%)`).join(', '),
        r.ingredienti.map(i => `${i.nome}: ${i.quantita} ${i.unita}`).join(', '),
        (r.prodotti.reduce((sum, p) => sum + parseFloat(p.resaPercentuale), 0) / r.prodotti.length).toFixed(2) + '%'
      ]);
    
    doc.autoTable({
      head: [['Data', 'Latte Usato (L)', 'Prodotti', 'Ingredienti', 'Resa Media (%)']],
      body: tableData,
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Generazione Documenti</h1>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Tipo di Report</label>
        <select
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          <option value="">Seleziona un tipo di report</option>
          <option value="qualitaLatte">Qualità Latte</option>
          <option value="movimentiLatte">Movimenti Latte</option>
          <option value="prezziLatte">Prezzi Latte</option>
          <option value="resa">Resa</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Data Inizio</label>
        <input
          type="date"
          value={dateRange.start}
          onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Data Fine</label>
        <input
          type="date"
          value={dateRange.end}
          onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        />
      </div>

      <button
        onClick={generateReport}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Genera Report
      </button>
    </div>
  );
}

export default Documentazione;