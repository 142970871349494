// components/resa/ResaList.js
import React from 'react';

function ResaList({ rese }) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Latte Usato (L)</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prodotti</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ingredienti</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resa Totale (%)</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rese.map((resa, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{resa.data}</td>
              <td className="px-6 py-4 whitespace-nowrap">{resa.latteUsato}</td>
              <td className="px-6 py-4">
                {resa.prodotti && resa.prodotti.map((prodotto, i) => (
                  <div key={i}>{prodotto.nome}: {prodotto.quantita}Kg ({prodotto.resaPercentuale}%)</div>
                ))}
              </td>
              <td className="px-6 py-4">
                {resa.ingredienti && resa.ingredienti.map((ingrediente, i) => (
                  <div key={i}>{ingrediente.nome}: {ingrediente.quantita} {ingrediente.unita} (Lotto: {ingrediente.lotto})</div>
                ))}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {resa.prodotti && (resa.prodotti.reduce((sum, p) => sum + parseFloat(p.resaPercentuale || 0), 0) / resa.prodotti.length).toFixed(2)}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ResaList;