import { useState, useEffect } from 'react';

function useQuoteLatte() {
  const [campioni, setCampioni] = useState(() => {
    // Load initial data from localStorage
    const savedCampioni = localStorage.getItem('quoteLatteCampioni');
    return savedCampioni ? JSON.parse(savedCampioni) : [];
  });

  useEffect(() => {
    // Save campioni to localStorage whenever it changes
    localStorage.setItem('quoteLatteCampioni', JSON.stringify(campioni));
  }, [campioni]);

  const addCampione = (newCampione) => {
    setCampioni(prev => [...prev, newCampione]);
  };

  const calculateMediaGeometrica = (data, key) => {
    if (data.length === 0) return 0;
    const product = data.reduce((acc, curr) => acc * parseFloat(curr[key]), 1);
    return Math.pow(product, 1 / data.length);
  };

  return {
    campioni,
    addCampione,
    calculateMediaGeometrica
  };
}

export default useQuoteLatte;