import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './styles/global.css';

// Import pages
import QuoteLatte from './pages/QuoteLatte';
import Charting from './pages/Charting';
import Fornitori from './pages/Fornitori';
import Prezzi from './pages/Prezzi';
import Serbatoi from './pages/Serbatoi';
import Resa from './pages/Resa';
import Documentazione from './pages/Documentazione';

// Import layout components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Header />
        <nav className="bg-white shadow-md">
          <ul className="flex justify-center space-x-4 py-4">
            <li><Link to="/" className="text-blue-600 hover:text-blue-800">Haccp Latte</Link></li>
            <li><Link to="/charting" className="text-blue-600 hover:text-blue-800">Charting</Link></li>
            <li><Link to="/fornitori" className="text-blue-600 hover:text-blue-800">Fornitori</Link></li>
            <li><Link to="/prezzi" className="text-blue-600 hover:text-blue-800">Prezzi</Link></li>
            <li><Link to="/serbatoi" className="text-blue-600 hover:text-blue-800">Serbatoi</Link></li>
            <li><Link to="/resa" className="text-blue-600 hover:text-blue-800">Resa</Link></li>
            <li><Link to="/documentazione" className="text-blue-600 hover:text-blue-800">Documentazione</Link></li>
          </ul>
        </nav>

        <main className="flex-grow container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<QuoteLatte />} />
            <Route path="/charting" element={<Charting />} />
            <Route path="/fornitori" element={<Fornitori />} />
            <Route path="/prezzi" element={<Prezzi />} />
            <Route path="/serbatoi" element={<Serbatoi />} />
            <Route path="/resa" element={<Resa />} />
            <Route path="/documentazione" element={<Documentazione />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;