import React, { useState } from 'react';
import PrezzoForm from '../components/prezzi/PrezzoForm';
import PrezziList from '../components/prezzi/PrezziList';
import PrezzoCalculator from '../components/prezzi/PrezzoCalculator';
import usePrezzi from '../hooks/usePrezzi';

function Prezzi() {
  const { prezzi, addPrezzo, deletePrezzo } = usePrezzi();
  const [activeTab, setActiveTab] = useState('list'); // 'list', 'add', 'calculate'

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Gestione Prezzi Latte</h1>

      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {['list', 'add', 'calculate'].map((tab) => (
              <button
                key={tab}
                className={`${
                  activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                onClick={() => setActiveTab(tab)}
              >
                {tab === 'list' && 'Lista Prezzi'}
                {tab === 'add' && 'Aggiungi Prezzo'}
                {tab === 'calculate' && 'Calcola Prezzo'}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {activeTab === 'list' && <PrezziList prezzi={prezzi} onDelete={deletePrezzo} />}
      {activeTab === 'add' && <PrezzoForm onSubmit={addPrezzo} />}
      {activeTab === 'calculate' && <PrezzoCalculator prezzi={prezzi} />}
    </div>
  );
}

export default Prezzi;