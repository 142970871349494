// hooks/useSerbatoi.js
import { useState, useEffect, useCallback } from 'react';

function useSerbatoi() {
  const [serbatoi, setSerbatoi] = useState(() => {
    const savedSerbatoi = localStorage.getItem('quoteLatteSerbatoi');
    return savedSerbatoi ? JSON.parse(savedSerbatoi) : [];
  });

  const [movimenti, setMovimenti] = useState(() => {
    const savedMovimenti = localStorage.getItem('quoteLatteMovimenti');
    return savedMovimenti ? JSON.parse(savedMovimenti) : [];
  });

  useEffect(() => {
    localStorage.setItem('quoteLatteSerbatoi', JSON.stringify(serbatoi));
  }, [serbatoi]);

  useEffect(() => {
    localStorage.setItem('quoteLatteMovimenti', JSON.stringify(movimenti));
  }, [movimenti]);

  const addMovimento = useCallback((newMovimento) => {
    setMovimenti(prev => [...prev, { ...newMovimento, id: Date.now().toString() }]);
  }, []);

  const updateMovimento = useCallback((id, updates) => {
    setMovimenti(prev => prev.map(movimento =>
      movimento.id === id ? { ...movimento, ...updates } : movimento
    ));
  }, []);

  const getLatteInProduzione = useCallback((date) => {
    return movimenti
      .filter(m => m.data === date && m.tipo === 'Produzione')
      .reduce((total, m) => total + parseFloat(m.quantita), 0);
  }, [movimenti]);

  const subtractLatteUsato = useCallback((date, quantita) => {
    const prodMovimento = movimenti.find(m => m.data === date && m.tipo === 'Produzione');
    if (prodMovimento) {
      const updatedQuantita = Math.max(0, parseFloat(prodMovimento.quantita) - quantita);
      updateMovimento(prodMovimento.id, { 
        ...prodMovimento, 
        quantita: updatedQuantita.toString() 
      });
    }
  }, [movimenti, updateMovimento]);

  return { 
    serbatoi, 
    movimenti, 
    addMovimento,
    updateMovimento,
    getLatteInProduzione,
    subtractLatteUsato
  };
}

export default useSerbatoi;