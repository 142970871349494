// components/resa/IngredientManager.js
import React, { useState } from 'react';

function IngredientManager({ ingredients, addIngredient, removeIngredient }) {
  const [newIngredient, setNewIngredient] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newIngredient.trim()) {
      addIngredient(newIngredient.trim());
      setNewIngredient('');
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Gestione Ingredienti Comuni</h3>
      <form onSubmit={handleSubmit} className="flex mb-4">
        <input
          type="text"
          value={newIngredient}
          onChange={(e) => setNewIngredient(e.target.value)}
          className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md"
          placeholder="Nuovo ingrediente"
        />
        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-r-md">
          Aggiungi
        </button>
      </form>
      <ul className="space-y-2">
        {ingredients.map((ingredient, index) => (
          <li key={index} className="flex justify-between items-center">
            <span>{ingredient}</span>
            <button
              onClick={() => removeIngredient(ingredient)}
              className="px-2 py-1 bg-red-600 text-white rounded-md"
            >
              Rimuovi
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default IngredientManager;