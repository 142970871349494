// components/resa/ResaForm.js
import React, { useState, useEffect } from 'react';

function ResaForm({ onSubmit, products, commonIngredients, latteInProduzione, onDateChange }) {
  const [resa, setResa] = useState({
    data: '',
    latteUsato: '',
    prodotti: [],
    ingredienti: []
  });

  const units = ['KG', 'g', 'L', 'mL']; // Add more units as needed

  useEffect(() => {
    if (resa.data) {
      onDateChange(resa.data);
    }
  }, [resa.data, onDateChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResa(prev => ({ ...prev, [name]: value }));
  };

  const handleProductChange = (index, field, value) => {
    setResa(prev => ({
      ...prev,
      prodotti: prev.prodotti.map((p, i) => i === index ? { ...p, [field]: value } : p)
    }));
  };

  const addProduct = () => {
    setResa(prev => ({
      ...prev,
      prodotti: [...prev.prodotti, { nome: '', quantita: '' }]
    }));
  };

  const handleIngredientChange = (index, field, value) => {
    setResa(prev => ({
      ...prev,
      ingredienti: prev.ingredienti.map((i, idx) => idx === index ? { ...i, [field]: value } : i)
    }));
  };

  const addIngredient = () => {
    setResa(prev => ({
      ...prev,
      ingredienti: [...prev.ingredienti, { nome: '', quantita: '', unita: 'KG', lotto: '' }]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(resa);
    setResa({
      data: '',
      latteUsato: '',
      prodotti: [],
      ingredienti: []
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Data</label>
        <input
          type="date"
          name="data"
          value={resa.data}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Latte in Produzione (L)</label>
        <input
          type="number"
          value={latteInProduzione}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100"
          disabled
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Latte Usato per questa Produzione (L)</label>
        <input
          type="number"
          name="latteUsato"
          value={resa.latteUsato}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-2">Prodotti</h3>
        {resa.prodotti.map((prodotto, index) => (
          <div key={index} className="flex space-x-2 mb-2">
            <select
              value={prodotto.nome}
              onChange={(e) => handleProductChange(index, 'nome', e.target.value)}
              className="px-2 py-1 border border-gray-300 rounded-md"
            >
              <option value="">Seleziona prodotto</option>
              {products.map((p, i) => (
                <option key={i} value={p}>{p}</option>
              ))}
            </select>
            <input
              type="number"
              value={prodotto.quantita}
              onChange={(e) => handleProductChange(index, 'quantita', e.target.value)}
              className="w-24 px-2 py-1 border border-gray-300 rounded-md"
              placeholder="Quantità (Kg)"
            />
          </div>
        ))}
        <button type="button" onClick={addProduct} className="mt-2 px-4 py-2 bg-green-600 text-white rounded-md">
          Aggiungi Prodotto
        </button>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-2">Ingredienti</h3>
        {resa.ingredienti.map((ingrediente, index) => (
          <div key={index} className="flex space-x-2 mb-2">
            <select
              value={ingrediente.nome}
              onChange={(e) => handleIngredientChange(index, 'nome', e.target.value)}
              className="px-2 py-1 border border-gray-300 rounded-md"
            >
              <option value="">Seleziona ingrediente</option>
              {commonIngredients.map((i, idx) => (
                <option key={idx} value={i}>{i}</option>
              ))}
            </select>
            <input
              type="number"
              value={ingrediente.quantita}
              onChange={(e) => handleIngredientChange(index, 'quantita', e.target.value)}
              className="w-24 px-2 py-1 border border-gray-300 rounded-md"
              placeholder="Quantità"
            />
            <select
              value={ingrediente.unita}
              onChange={(e) => handleIngredientChange(index, 'unita', e.target.value)}
              className="px-2 py-1 border border-gray-300 rounded-md"
            >
              {units.map((unit, idx) => (
                <option key={idx} value={unit}>{unit}</option>
              ))}
            </select>
            <input
              type="text"
              value={ingrediente.lotto}
              onChange={(e) => handleIngredientChange(index, 'lotto', e.target.value)}
              className="w-32 px-2 py-1 border border-gray-300 rounded-md"
              placeholder="Lotto"
            />
          </div>
        ))}
        <button type="button" onClick={addIngredient} className="mt-2 px-4 py-2 bg-green-600 text-white rounded-md">
          Aggiungi Ingrediente
        </button>
      </div>

      <button
        type="submit"
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Registra Resa
      </button>
    </form>
  );
}

export default ResaForm;