import React from 'react';
import useFornitori from '../../hooks/useFornitori';

function CampioniList({ campioni }) {
  const { fornitori } = useFornitori();

  const getFornitoreNome = (fornitoreId) => {
    const fornitore = fornitori.find(f => f.id === fornitoreId);
    return fornitore ? fornitore.name : 'Fornitore sconosciuto';
  };

  return (
    <div className="mt-8 overflow-x-auto">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Lista Campioni</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fornitore</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CBT (UFC/ml)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cellule Somatiche (cell/ml)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Proteine (%)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grassi (%)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lattosio (%)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Caseine (%)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Urea (mg/dl)</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Punto di Congelamento (°C)</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {campioni.map((campione, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{getFornitoreNome(campione.fornitoreId)}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.date}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.cbt}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.celluleSomatiche}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.proteine}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.grassi}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.lattosio}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.caseine}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.urea}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{campione.puntoCongelamento}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {campioni.length === 0 && (
        <p className="text-gray-500 text-center mt-4">Nessun campione registrato.</p>
      )}
    </div>
  );
}

export default CampioniList;