import React, { useState } from 'react';
import useFornitori from '../hooks/useFornitori';

function Fornitori() {
  const { fornitori, addFornitore } = useFornitori();
  const [newFornitore, setNewFornitore] = useState({
    name: '',
    piva: '',
    codiceFiscale: '',
    address: '',
    cap: '',
    city: '',
    province: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewFornitore(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addFornitore(newFornitore);
    setNewFornitore({
      name: '',
      piva: '',
      codiceFiscale: '',
      address: '',
      cap: '',
      city: '',
      province: '',
      email: '',
      phone: ''
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Gestione Fornitori</h1>
      
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Ragione Sociale</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newFornitore.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="piva" className="block text-sm font-medium text-gray-700 mb-1">Partita IVA</label>
            <input
              type="text"
              id="piva"
              name="piva"
              value={newFornitore.piva}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="codiceFiscale" className="block text-sm font-medium text-gray-700 mb-1">Codice Fiscale</label>
            <input
              type="text"
              id="codiceFiscale"
              name="codiceFiscale"
              value={newFornitore.codiceFiscale}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Indirizzo</label>
            <input
              type="text"
              id="address"
              name="address"
              value={newFornitore.address}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="cap" className="block text-sm font-medium text-gray-700 mb-1">CAP</label>
            <input
              type="text"
              id="cap"
              name="cap"
              value={newFornitore.cap}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">Città</label>
            <input
              type="text"
              id="city"
              name="city"
              value={newFornitore.city}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="province" className="block text-sm font-medium text-gray-700 mb-1">Provincia</label>
            <input
              type="text"
              id="province"
              name="province"
              value={newFornitore.province}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={newFornitore.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Telefono</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={newFornitore.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          Aggiungi Fornitore
        </button>
      </form>

      <h2 className="text-xl font-semibold text-gray-800 mb-4">Lista Fornitori</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ragione Sociale</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Partita IVA</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Codice Fiscale</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Città</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provincia</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {fornitori.map((fornitore) => (
              <tr key={fornitore.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{fornitore.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{fornitore.piva}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{fornitore.codiceFiscale}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{fornitore.city}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{fornitore.province}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Fornitori;