import React from 'react';

function TankVisualizer({ serbatoio, onClick }) {
  const fillPercentage = (serbatoio.currentVolume / serbatoio.capacita) * 100;

  return (
    <div 
      className="w-24 h-36 bg-gray-200 rounded-lg relative cursor-pointer overflow-hidden"
      onClick={onClick}
    >
      <div 
        className="absolute bottom-0 left-0 right-0 bg-blue-500 transition-all duration-300"
        style={{ height: `${fillPercentage}%` }}
      ></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center text-xs">
        <span className="font-bold">{serbatoio.nome}</span>
        <span>{serbatoio.currentVolume}L</span>
        <span>/{serbatoio.capacita}L</span>
      </div>
    </div>
  );
}

export default TankVisualizer;