import React from 'react';

function PrezziList({ prezzi, onDelete }) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data Inizio</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prezzo Base</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bonus Proteine</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bonus Grassi</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Penalità Cellule</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Penalità Carica Batterica</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Azioni</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {prezzi.map((prezzo) => (
            <tr key={prezzo.id}>
              <td className="px-6 py-4 whitespace-nowrap">{prezzo.dataInizio}</td>
              <td className="px-6 py-4 whitespace-nowrap">{prezzo.prezzoBase.toFixed(3)} €/L</td>
              <td className="px-6 py-4 whitespace-nowrap">{prezzo.bonusProteine.toFixed(3)} €/%</td>
              <td className="px-6 py-4 whitespace-nowrap">{prezzo.bonusGrassi.toFixed(3)} €/%</td>
              <td className="px-6 py-4 whitespace-nowrap">{prezzo.penalidadCellule.toFixed(3)} €/1000 cell/ml</td>
              <td className="px-6 py-4 whitespace-nowrap">{prezzo.penalidadCaricaBatterica.toFixed(3)} €/1000 UFC/ml</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => onDelete(prezzo.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  Elimina
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PrezziList;