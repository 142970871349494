import { useMemo } from 'react';

function useCharting() {
  const prepareChartData = useMemo(() => (campioni) => {
    const cbtThreshold = 100000;
    const celluleThreshold = 400000;

    return campioni.map(campione => ({
      date: campione.date,
      cbt: parseFloat(campione.cbt),
      celluleSomatiche: parseFloat(campione.celluleSomatiche),
      cbtThreshold,
      celluleThreshold,
      proteine: parseFloat(campione.proteine),
      grassi: parseFloat(campione.grassi),
      lattosio: parseFloat(campione.lattosio),
      caseine: parseFloat(campione.caseine),
      urea: parseFloat(campione.urea),
      puntoCongelamento: parseFloat(campione.puntoCongelamento)
    })).sort((a, b) => new Date(a.date) - new Date(b.date));
  }, []);

  return { prepareChartData };
}

export default useCharting;