import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function ResaChart({ rese, products }) {
  const [selectedProduct, setSelectedProduct] = useState('overall');

  const chartData = rese.map(resa => {
    const dataPoint = {
      data: resa.data,
      overall: resa.prodotti && resa.prodotti.length > 0
        ? resa.prodotti.reduce((sum, p) => sum + parseFloat(p.resaPercentuale || 0), 0) / resa.prodotti.length
        : 0
    };
    if (resa.prodotti) {
      resa.prodotti.forEach(prodotto => {
        dataPoint[prodotto.nome] = parseFloat(prodotto.resaPercentuale || 0);
      });
    }
    return dataPoint;
  });

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="product-select" className="mr-2">Seleziona Prodotto:</label>
        <select
          id="product-select"
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          className="px-2 py-1 border border-gray-300 rounded-md"
        >
          <option value="overall">Resa Totale</option>
          {products.map((product, index) => (
            <option key={index} value={product}>{product}</option>
          ))}
        </select>
      </div>
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line 
              type="monotone" 
              dataKey={selectedProduct} 
              stroke="#8884d8" 
              activeDot={{ r: 8 }} 
              name={selectedProduct === 'overall' ? 'Resa Totale' : selectedProduct}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ResaChart;