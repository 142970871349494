import React, { useMemo } from 'react';
import CampioneForm from '../components/quoteLatte/CampioneForm';
import CampioniList from '../components/quoteLatte/CampioniList';
import MediaGeometrica from '../components/quoteLatte/MediaGeometrica';
import useQuoteLatte from '../hooks/useQuoteLatte';

function QuoteLatte() {
  const { campioni, addCampione, calculateMediaGeometrica } = useQuoteLatte();

  const sortedCampioni = useMemo(() => {
    return [...campioni].sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [campioni]);

  const recentCBTCampioni = useMemo(() => {
    if (sortedCampioni.length === 0) return [];
    const latestDate = new Date(sortedCampioni[0].date);
    const twoMonthsAgo = new Date(latestDate);
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    return sortedCampioni.filter(campione => new Date(campione.date) >= twoMonthsAgo);
  }, [sortedCampioni]);

  const recentCelluleCampioni = useMemo(() => {
    if (sortedCampioni.length === 0) return [];
    const latestDate = new Date(sortedCampioni[0].date);
    const threeMonthsAgo = new Date(latestDate);
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    return sortedCampioni.filter(campione => new Date(campione.date) >= threeMonthsAgo);
  }, [sortedCampioni]);

  const cbtMedia = calculateMediaGeometrica(recentCBTCampioni, 'cbt');
  const celluleMedia = calculateMediaGeometrica(recentCelluleCampioni, 'celluleSomatiche');

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Gestione Haccp Latte</h1>
      <CampioneForm onAddCampione={addCampione} />
      <CampioniList campioni={sortedCampioni} />
      
      <div className="mt-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Media Geometrica (Ultimi dati)</h2>
        <MediaGeometrica 
          cbtMedia={cbtMedia}
          celluleMedia={celluleMedia}
          cbtPeriod="2 mesi"
          cellulePeriod="3 mesi"
          cbtSampleCount={recentCBTCampioni.length}
          celluleSampleCount={recentCelluleCampioni.length}
        />
      </div>
    </div>
  );
}

export default QuoteLatte;