import React from 'react';
import { LineChart as RechartsLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

function LineChart({ data, xDataKey, lines, thresholds }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <RechartsLineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        {lines.map((line, index) => (
          <Line 
            key={index}
            type="monotone" 
            dataKey={line.dataKey} 
            stroke={line.stroke} 
            name={line.name}
            strokeDasharray={line.strokeDasharray}
          />
        ))}
        {thresholds && thresholds.map((threshold, index) => (
          <ReferenceLine 
            key={index}
            y={threshold.value} 
            label={threshold.label} 
            stroke={threshold.stroke} 
            strokeDasharray="3 3" 
          />
        ))}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
}

export default LineChart;