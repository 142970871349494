// components/resa/ProductManager.js
import React, { useState } from 'react';

function ProductManager({ products, addProduct, removeProduct }) {
  const [newProduct, setNewProduct] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newProduct.trim()) {
      addProduct(newProduct.trim());
      setNewProduct('');
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Gestione Prodotti</h3>
      <form onSubmit={handleSubmit} className="flex mb-4">
        <input
          type="text"
          value={newProduct}
          onChange={(e) => setNewProduct(e.target.value)}
          className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md"
          placeholder="Nuovo prodotto"
        />
        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-r-md">
          Aggiungi
        </button>
      </form>
      <ul className="space-y-2">
        {products.map((product, index) => (
          <li key={index} className="flex justify-between items-center">
            <span>{product}</span>
            <button
              onClick={() => removeProduct(product)}
              className="px-2 py-1 bg-red-600 text-white rounded-md"
            >
              Rimuovi
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProductManager;