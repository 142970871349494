import React from 'react';

function MediaGeometrica({ cbtMedia, celluleMedia, cbtPeriod, cellulePeriod, cbtSampleCount, celluleSampleCount }) {
  const cbtThreshold = 100000; // UFC/ml
  const celluleThreshold = 400000; // cell/ml

  return (
    <div className="bg-gray-100 p-4 rounded-md">
      <div className="mb-4">
        <h3 className="text-lg font-semibold">CBT (ultimi {cbtPeriod})</h3>
        <p className={`text-2xl font-bold ${cbtMedia > cbtThreshold ? 'text-red-600' : 'text-green-600'}`}>
          {cbtMedia.toFixed(2)} UFC/ml
        </p>
        <p className="text-sm text-gray-600">Basato su {cbtSampleCount} campioni</p>
        {cbtMedia > cbtThreshold && (
          <p className="text-red-600 text-sm">Supera il limite di {cbtThreshold} UFC/ml</p>
        )}
      </div>
      <div>
        <h3 className="text-lg font-semibold">Cellule Somatiche (ultimi {cellulePeriod})</h3>
        <p className={`text-2xl font-bold ${celluleMedia > celluleThreshold ? 'text-red-600' : 'text-green-600'}`}>
          {celluleMedia.toFixed(2)} cell/ml
        </p>
        <p className="text-sm text-gray-600">Basato su {celluleSampleCount} campioni</p>
        {celluleMedia > celluleThreshold && (
          <p className="text-red-600 text-sm">Supera il limite di {celluleThreshold} cell/ml</p>
        )}
      </div>
    </div>
  );
}

export default MediaGeometrica;