import React, { useState } from 'react';
import useFornitori from '../../hooks/useFornitori';

function CampioneForm({ onAddCampione }) {
  const { fornitori } = useFornitori();
  const [campione, setCampione] = useState({
    fornitoreId: '',
    date: '',
    cbt: '',
    celluleSomatiche: '',
    proteine: '',
    grassi: '',
    lattosio: '',
    caseine: '',
    urea: '',
    puntoCongelamento: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampione(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddCampione(campione);
    setCampione({
      fornitoreId: '',
      date: '',
      cbt: '',
      celluleSomatiche: '',
      proteine: '',
      grassi: '',
      lattosio: '',
      caseine: '',
      urea: '',
      puntoCongelamento: ''
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <label htmlFor="fornitoreId" className="block text-sm font-medium text-gray-700 mb-1">Fornitore</label>
          <select
            id="fornitoreId"
            name="fornitoreId"
            value={campione.fornitoreId}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Seleziona un fornitore</option>
            {fornitori.map((fornitore) => (
              <option key={fornitore.id} value={fornitore.id}>{fornitore.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">Data</label>
          <input
            type="date"
            id="date"
            name="date"
            value={campione.date}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="cbt" className="block text-sm font-medium text-gray-700 mb-1">CBT (UFC/ml)</label>
          <input
            type="number"
            id="cbt"
            name="cbt"
            value={campione.cbt}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="celluleSomatiche" className="block text-sm font-medium text-gray-700 mb-1">Cellule Somatiche (cell/ml)</label>
          <input
            type="number"
            id="celluleSomatiche"
            name="celluleSomatiche"
            value={campione.celluleSomatiche}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="proteine" className="block text-sm font-medium text-gray-700 mb-1">Proteine (%)</label>
          <input
            type="number"
            id="proteine"
            name="proteine"
            value={campione.proteine}
            onChange={handleChange}
            step="0.01"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="grassi" className="block text-sm font-medium text-gray-700 mb-1">Grassi (%)</label>
          <input
            type="number"
            id="grassi"
            name="grassi"
            value={campione.grassi}
            onChange={handleChange}
            step="0.01"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="lattosio" className="block text-sm font-medium text-gray-700 mb-1">Lattosio (%)</label>
          <input
            type="number"
            id="lattosio"
            name="lattosio"
            value={campione.lattosio}
            onChange={handleChange}
            step="0.01"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="caseine" className="block text-sm font-medium text-gray-700 mb-1">Caseine (%)</label>
          <input
            type="number"
            id="caseine"
            name="caseine"
            value={campione.caseine}
            onChange={handleChange}
            step="0.01"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="urea" className="block text-sm font-medium text-gray-700 mb-1">Urea (mg/dl)</label>
          <input
            type="number"
            id="urea"
            name="urea"
            value={campione.urea}
            onChange={handleChange}
            step="0.1"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="puntoCongelamento" className="block text-sm font-medium text-gray-700 mb-1">Punto di Congelamento (°C)</label>
          <input
            type="number"
            id="puntoCongelamento"
            name="puntoCongelamento"
            value={campione.puntoCongelamento}
            onChange={handleChange}
            step="0.001"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Aggiungi Campione
        </button>
      </div>
    </form>
  );
}

export default CampioneForm;