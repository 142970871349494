import React, { useState } from 'react';

function PrezzoForm({ onSubmit }) {
  const [prezzo, setPrezzo] = useState({
    dataInizio: '',
    prezzoBase: '',
    bonusProteine: '',
    bonusGrassi: '',
    penalidadCellule: '',
    penalidadCaricaBatterica: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrezzo(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...prezzo,
      prezzoBase: parseFloat(prezzo.prezzoBase),
      bonusProteine: parseFloat(prezzo.bonusProteine),
      bonusGrassi: parseFloat(prezzo.bonusGrassi),
      penalidadCellule: parseFloat(prezzo.penalidadCellule),
      penalidadCaricaBatterica: parseFloat(prezzo.penalidadCaricaBatterica)
    });
    setPrezzo({
      dataInizio: '',
      prezzoBase: '',
      bonusProteine: '',
      bonusGrassi: '',
      penalidadCellule: '',
      penalidadCaricaBatterica: ''
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Data di Inizio</label>
        <input
          type="date"
          name="dataInizio"
          value={prezzo.dataInizio}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Prezzo Base (€/L)</label>
        <input
          type="number"
          name="prezzoBase"
          value={prezzo.prezzoBase}
          onChange={handleChange}
          step="0.001"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Bonus Proteine (€/punto percentuale)</label>
        <input
          type="number"
          name="bonusProteine"
          value={prezzo.bonusProteine}
          onChange={handleChange}
          step="0.001"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Bonus Grassi (€/punto percentuale)</label>
        <input
          type="number"
          name="bonusGrassi"
          value={prezzo.bonusGrassi}
          onChange={handleChange}
          step="0.001"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Penalità Cellule Somatiche (€/1000 cell/ml)</label>
        <input
          type="number"
          name="penalidadCellule"
          value={prezzo.penalidadCellule}
          onChange={handleChange}
          step="0.001"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Penalità Carica Batterica (€/1000 UFC/ml)</label>
        <input
          type="number"
          name="penalidadCaricaBatterica"
          value={prezzo.penalidadCaricaBatterica}
          onChange={handleChange}
          step="0.001"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Aggiungi Prezzo
      </button>
    </form>
  );
}

export default PrezzoForm;