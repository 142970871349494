// components/serbatoi/MovimentoForm.js
import React, { useState } from 'react';

function MovimentoForm({ serbatoi, fornitori, onSubmit }) {
  const [movimento, setMovimento] = useState({
    data: '',
    lotto: '',
    tipo: 'Ingresso',
    quantita: '',
    fornitoreId: '',
    serbatoioId: '',
    serbatoioOriginId: '',
    serbatoioDestinationId: '',
    noteProduzione: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMovimento(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...movimento,
      quantita: Number(movimento.quantita)
    });
    setMovimento({
      data: '',
      lotto: '',
      tipo: 'Ingresso',
      quantita: '',
      fornitoreId: '',
      serbatoioId: '',
      serbatoioOriginId: '',
      serbatoioDestinationId: '',
      noteProduzione: ''
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Data</label>
        <input
          type="date"
          name="data"
          value={movimento.data}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Lotto</label>
        <input
          type="text"
          name="lotto"
          value={movimento.lotto}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Tipo</label>
        <select
          name="tipo"
          value={movimento.tipo}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="Ingresso">Ingresso</option>
          <option value="Uscita">Uscita</option>
          <option value="Trasferimento">Trasferimento</option>
          <option value="Produzione">Produzione</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Quantità (L)</label>
        <input
          type="number"
          name="quantita"
          value={movimento.quantita}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      {(movimento.tipo === 'Ingresso' || movimento.tipo === 'Produzione') && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Fornitore</label>
          <select
            name="fornitoreId"
            value={movimento.fornitoreId}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Seleziona un fornitore</option>
            {fornitori.map(fornitore => (
              <option key={fornitore.id} value={fornitore.id}>{fornitore.name}</option>
            ))}
          </select>
        </div>
      )}
      {(movimento.tipo === 'Ingresso' || movimento.tipo === 'Uscita' || movimento.tipo === 'Produzione') && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Serbatoio</label>
          <select
            name="serbatoioId"
            value={movimento.serbatoioId}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Seleziona un serbatoio</option>
            {serbatoi.map(serbatoio => (
              <option key={serbatoio.id} value={serbatoio.id}>{serbatoio.nome}</option>
            ))}
          </select>
        </div>
      )}
      {movimento.tipo === 'Trasferimento' && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700">Serbatoio di Origine</label>
            <select
              name="serbatoioOriginId"
              value={movimento.serbatoioOriginId}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Seleziona un serbatoio</option>
              {serbatoi.map(serbatoio => (
                <option key={serbatoio.id} value={serbatoio.id}>{serbatoio.nome}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Serbatoio di Destinazione</label>
            <select
              name="serbatoioDestinationId"
              value={movimento.serbatoioDestinationId}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Seleziona un serbatoio</option>
              {serbatoi.map(serbatoio => (
                <option key={serbatoio.id} value={serbatoio.id}>{serbatoio.nome}</option>
              ))}
            </select>
          </div>
        </>
      )}
      {movimento.tipo === 'Produzione' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Note Produzione</label>
          <textarea
            name="noteProduzione"
            value={movimento.noteProduzione}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      )}
      <button
        type="submit"
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Registra Movimento
      </button>
    </form>
  );
}

export default MovimentoForm;