import React, { useState } from 'react';

function PrezzoCalculator({ prezzi }) {
  const [latte, setLatte] = useState({
    data: '',
    quantita: '',
    proteine: '',
    grassi: '',
    cellule: '',
    caricaBatterica: ''
  });
  const [risultato, setRisultato] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLatte(prev => ({ ...prev, [name]: value }));
  };

  const calcolaPrezzo = (e) => {
    e.preventDefault();
    const prezzoApplicabile = prezzi
      .filter(p => new Date(p.dataInizio) <= new Date(latte.data))
      .sort((a, b) => new Date(b.dataInizio) - new Date(a.dataInizio))[0];

    if (!prezzoApplicabile) {
      alert("Nessun prezzo applicabile per la data selezionata");
      return;
    }

    const prezzoBase = prezzoApplicabile.prezzoBase * latte.quantita;
    const bonusProteine = prezzoApplicabile.bonusProteine * (latte.proteine - 3.2) * latte.quantita;
    const bonusGrassi = prezzoApplicabile.bonusGrassi * (latte.grassi - 3.5) * latte.quantita;
    const penalidadCellule = prezzoApplicabile.penalidadCellule * (latte.cellule / 1000) * latte.quantita;
    const penalidadCaricaBatterica = prezzoApplicabile.penalidadCaricaBatterica * (latte.caricaBatterica / 1000) * latte.quantita;

    const prezzoTotale = prezzoBase + bonusProteine + bonusGrassi - penalidadCellule - penalidadCaricaBatterica;

    setRisultato({
      prezzoBase,
      bonusProteine,
      bonusGrassi,
      penalidadCellule,
      penalidadCaricaBatterica,
      prezzoTotale
    });
  };

  return (
    <div>
      <form onSubmit={calcolaPrezzo} className="space-y-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Data</label>
          <input
            type="date"
            name="data"
            value={latte.data}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Quantità (L)</label>
          <input
            type="number"
            name="quantita"
            value={latte.quantita}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Proteine (%)</label>
          <input
            type="number"
            name="proteine"
            value={latte.proteine}
            onChange={handleChange}
            step="0.1"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Grassi (%)</label>
          <input
            type="number"
            name="grassi"
            value={latte.grassi}
            onChange={handleChange}
            step="0.1"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Cellule Somatiche (cell/ml)</label>
          <input
            type="number"
            name="cellule"
            value={latte.cellule}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Carica Batterica (UFC/ml)</label>
          <input
            type="number"
            name="caricaBatterica"
            value={latte.caricaBatterica}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Calcola Prezzo
        </button>
      </form>

      {risultato && (
        <div className="mt-6 p-4 bg-gray-100 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Risultato del Calcolo</h3>
          <p>Prezzo Base: {risultato.prezzoBase.toFixed(2)} €</p>
          <p>Bonus Proteine: {risultato.bonusProteine.toFixed(2)} €</p>
          <p>Bonus Grassi: {risultato.bonusGrassi.toFixed(2)} €</p>
          <p>Penalità Cellule: {risultato.penalidadCellule.toFixed(2)} €</p>
          <p>Penalità Carica Batterica: {risultato.penalidadCaricaBatterica.toFixed(2)} €</p>
          <p className="font-bold mt-2">Prezzo Totale: {risultato.prezzoTotale.toFixed(2)} €</p>
        </div>
      )}
    </div>
  );
}

export default PrezzoCalculator;