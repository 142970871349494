// pages/Serbatoi.js
import React, { useState } from 'react';
import useSerbatoi from '../hooks/useSerbatoi';
import useFornitori from '../hooks/useFornitori';
import TankVisualizer from '../components/serbatoi/TankVisualizer';
import MovimentoForm from '../components/serbatoi/MovimentoForm';
import MovimentiList from '../components/serbatoi/MovimentiList';

function Serbatoi() {
  const { serbatoi, addSerbatoio, updateSerbatoio, movimenti, addMovimento } = useSerbatoi();
  const { fornitori } = useFornitori();
  const [selectedTank, setSelectedTank] = useState(null);

  const handleAddSerbatoio = (newSerbatoio) => {
    addSerbatoio({ ...newSerbatoio, currentVolume: 0 });
  };

  const handleMovimento = (movimento) => {
    addMovimento(movimento);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Gestione Serbatoi</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Serbatoi</h2>
          <div className="flex flex-wrap gap-4">
            {serbatoi.map(serbatoio => (
              <TankVisualizer
                key={serbatoio.id}
                serbatoio={serbatoio}
                onClick={() => setSelectedTank(serbatoio)}
              />
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Aggiungi Serbatoio</h2>
          <form onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            handleAddSerbatoio({
              nome: formData.get('nome'),
              capacita: Number(formData.get('capacita'))
            });
            e.target.reset();
          }} className="space-y-4">
            <input
              type="text"
              name="nome"
              placeholder="Nome Serbatoio"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="number"
              name="capacita"
              placeholder="Capacità (litri)"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
            <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">
              Aggiungi Serbatoio
            </button>
          </form>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Registra Movimento
        </h2>
        <MovimentoForm
          serbatoi={serbatoi}
          fornitori={fornitori}
          onSubmit={handleMovimento}
        />
      </div>

      <div>
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Storico Movimenti</h2>
        <MovimentiList movimenti={movimenti} serbatoi={serbatoi} fornitori={fornitori} />
      </div>
    </div>
  );
}

export default Serbatoi;