import React, { useState, useMemo } from 'react';
import LineChart from '../components/charting/LineChart';
import useQuoteLatte from '../hooks/useQuoteLatte';
import useCharting from '../hooks/useCharting';
import useFornitori from '../hooks/useFornitori';

function Charting() {
  const { campioni, calculateMediaGeometrica } = useQuoteLatte();
  const { prepareChartData } = useCharting();
  const { fornitori } = useFornitori();
  const [selectedFornitoreId, setSelectedFornitoreId] = useState('');

  const filteredCampioni = useMemo(() => {
    return selectedFornitoreId
      ? campioni.filter(campione => campione.fornitoreId === selectedFornitoreId)
      : campioni;
  }, [campioni, selectedFornitoreId]);

  const chartData = prepareChartData(filteredCampioni);

  const geometricMeanCBT = calculateMediaGeometrica(filteredCampioni, 'cbt');
  const geometricMeanCellule = calculateMediaGeometrica(filteredCampioni, 'celluleSomatiche');

  // Legal thresholds
  const cbtThreshold = 100000; // UFC/ml
  const celluleThreshold = 400000; // cell/ml

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold text-blue-600 mb-6">Grafici Haccp Latte</h1>
      
      <div className="mb-4">
        <label htmlFor="fornitoreFilter" className="block text-sm font-medium text-gray-700 mb-1">Filtra per Fornitore</label>
        <select
          id="fornitoreFilter"
          value={selectedFornitoreId}
          onChange={(e) => setSelectedFornitoreId(e.target.value)}
          className="w-full md:w-auto px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Tutti i Fornitori</option>
          {fornitori.map((fornitore) => (
            <option key={fornitore.id} value={fornitore.id}>{fornitore.name}</option>
          ))}
        </select>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Andamento CBT e Cellule Somatiche</h2>
        <LineChart 
          data={chartData} 
          xDataKey="date" 
          lines={[
            { dataKey: 'cbt', name: 'CBT (UFC/ml)', stroke: '#8884d8' },
            { dataKey: 'celluleSomatiche', name: 'Cellule Somatiche (cell/ml)', stroke: '#82ca9d' }
          ]}
          thresholds={[
            { value: cbtThreshold, label: 'Soglia CBT', stroke: '#ff0000' },
            { value: celluleThreshold, label: 'Soglia Cellule', stroke: '#00ff00' }
          ]}
        />
        <div className="mt-4 p-4 bg-gray-100 rounded-md">
          <p>Media Geometrica CBT: {geometricMeanCBT.toFixed(2)} UFC/ml</p>
          <p>Media Geometrica Cellule Somatiche: {geometricMeanCellule.toFixed(2)} cell/ml</p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Composizione del Latte</h2>
        <LineChart 
          data={chartData} 
          xDataKey="date" 
          lines={[
            { dataKey: 'proteine', name: 'Proteine (%)', stroke: '#8884d8' },
            { dataKey: 'grassi', name: 'Grassi (%)', stroke: '#82ca9d' },
            { dataKey: 'lattosio', name: 'Lattosio (%)', stroke: '#ffc658' }
          ]}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Caseine e Urea</h2>
          <LineChart 
            data={chartData} 
            xDataKey="date" 
            lines={[
              { dataKey: 'caseine', name: 'Caseine (%)', stroke: '#8884d8' },
              { dataKey: 'urea', name: 'Urea (mg/dl)', stroke: '#82ca9d' }
            ]}
          />
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Punto di Congelamento</h2>
          <LineChart 
            data={chartData} 
            xDataKey="date" 
            lines={[
              { dataKey: 'puntoCongelamento', name: 'Punto di Congelamento (°C)', stroke: '#8884d8' }
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Charting;