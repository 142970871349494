import { useState, useEffect } from 'react';

function useFornitori() {
  const [fornitori, setFornitori] = useState(() => {
    const savedFornitori = localStorage.getItem('quoteLatteFornitori');
    return savedFornitori ? JSON.parse(savedFornitori) : [];
  });

  useEffect(() => {
    localStorage.setItem('quoteLatteFornitori', JSON.stringify(fornitori));
  }, [fornitori]);

  const addFornitore = (newFornitore) => {
    setFornitori(prev => [...prev, { ...newFornitore, id: Date.now().toString() }]);
  };

  return { fornitori, addFornitore };
}

export default useFornitori;